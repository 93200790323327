/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

body {
  padding-bottom: 6rem !important;
}

html {
  height: 100%;
}

body,
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
}

strong {
  font-weight: bold;
}

.Toastify__toast-container {
  z-index: 10000 !important;
}

.Toastify__toast--default {
  background: #3498db !important;
}

.Toastify__toast--info {
  background: #3498db !important;
}

.Toastify__toast--success {
  background: #27ae60 !important;
}

.Toastify__toast--warning {
  background: #e74c3c !important;
}
